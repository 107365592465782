import React from "react";  
import './header.css'; 
import { environment } from "../../enviroments/enviroments";

export default function Header({dataHeader, activeMenu, numberWhats}){       
    const IMG_HOST = environment.API_IMAGEN; 
    return( 
        <>    
        <header> 
            <div> 
            <div className="container-xxl py-3 ">   
                <nav className="row my-1" > 
                    <div className="col-md-2 col-6 height-img-custome d-flex justify-content-start justify-content-md-center " > 
                        <img src={`${IMG_HOST}/${dataHeader.nombreImagen}`} className="responsiveImgHeader bg-danger w-auto  "  alt="logitipo de pc oriente" /> 
                    </div>  
                    <div className="col-8 d-md-flex d-none flex-column centerContent ">  
                        <p className="mb-0 fw-bold h3 ">{dataHeader.nombreEmpresa}</p> 
                        <p className="h5 mb-0 " >{dataHeader.esloganEmpresa}</p>
                    </div> 
                    <div className="col-md-2 col-6 text-end pe-2 centerContent justify-content-end " > 
                        <button onClick={activeMenu} className="btn btn-sm btn-primary d-md-none " >  
                            <i className="fa-solid fa-bars"></i>
                        </button> 
                        <div className='col-2 d-md-block d-none ' > 
                    <div className='' > 
                      <div className='me-3' > 
                        <a href={`https://api.whatsapp.com/send?phone=${numberWhats}`} target="_blank" rel="noreferrer"  > <i className=" h2 fa-brands fa-whatsapp bg-whatsapp "></i> </a>  
                      </div>  
                    </div>
                  </div> 
                    </div>
                </nav>  
            </div> 
            </div> 
        </header>
        </>
    )
}