import React from "react";  
import { environment } from "../enviroments/enviroments";


export default function Card ({nombreImagen, Cardtitle, children, frameVideo, imgVideo}){  
    const IMG_HOST = environment.API_IMAGEN;  
    let Img = <img className="responsiveImg imgSizeCustome " src={`${IMG_HOST}/${nombreImagen}`} alt={nombreImagen} /> ;  
    let Video = <div className="col-md-6 widthFrameCustome "  dangerouslySetInnerHTML={{__html: frameVideo}}></div>; 
    return (<>  
    <div className="card rounded-5 overflow-hidden shadow " >   
    {/* frameContainerCustome imgSizeCustome*/}
    <div className={imgVideo == 0 ? 'imgSizeCustome':'frameContainerCustome'} >   
        { 
            imgVideo == 0 ? Img :  Video 
        }
    </div>
        <div className="card-body" >   
            <h5 className="card-title">{Cardtitle}</h5>
            <div> 
                <p>{children}</p>
            </div>
        </div>
    </div>
    </>)
}