import { useState, useEffect } from "react";  

export default function useFetch () {  

    const [data, setData] = useState([]); 
    const [ispending, setIspending] = useState(false) ; 
    const [error, setError ] = useState([]);  
        let getAJAX =  async (url) => { 
            setIspending(true); 
            try{ 
                let res = await fetch(url)  
                let jsonRes = await res.json() 
                setData(jsonRes); 
                setIspending(false);  
                if (!res.ok) { 
                    let error = { 
                        status: res.status, 
                        statusText: res.statusText,
                    } 
                    throw error
                }
            }catch(err) { 
                setError(err) 
                setIspending(false); 
            }
        }  
    return {   
        data, 
        ispending, 
        error, 
        getAJAX
    }
}

