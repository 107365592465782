import { Swiper, SwiperSlide } from 'swiper/react';   
import {Autoplay, Navigation, Pagination, A11y, EffectFade } from 'swiper'; 
import React from 'react';  
import './swipper.css' 
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';   
import "swiper/css/effect-fade";
import useFetch from '../hooks/usefetch'; 
import { useEffect } from 'react'; 
import Louder from './louder'; 
import { environment } from '../enviroments/enviroments';

export default function Slider({GetIdCliente}){   
  const fetchSlider = useFetch();   
  const IMG_HOST = environment.API_IMAGEN; 
  const API_URL = environment.API_URL

  useEffect(() =>{      
    getAsyncIdCliente(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); 
  
  const getAsyncIdCliente = async () =>{  
    let idClienteParams = await GetIdCliente(); 
    if(!idClienteParams) return (()=>{ 
      fetchSlider.getAJAX(`${API_URL}/promociones/slider`); 
    })(); 

    if(idClienteParams){ 
      fetchSlider.getAJAX(`${API_URL}/promociones-clientes/slider/${idClienteParams}`); 
    }
  } 

  const viewData = () =>{ 
    console.log(fetchSlider.data); 
  }
    return(<>   
    { fetchSlider.ispending ? (<Louder></Louder>) : <Swiper
      // install Swiper modules 
      className='w-100 customeSizeSwipper mb-md-0 mb-5 max-width-slider '
      modules={[Navigation, Pagination, A11y, Autoplay, EffectFade]}
      spaceBetween={50}
      slidesPerView={1} 
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => swiper}
      onSlideChange={() => ""} 
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }} 
      loop={true} 
      effect={"fade"}
    > 
    {
     fetchSlider.data.map((data, index)=>{ 
        return <SwiperSlide key={data.idSlider} className='w-100' > 
            <div onClick={viewData} className='position-relative d-flex justify-content-center align-items-end w-100 aspect-ratio-custome ' >
                <div className={`styles-text-slider centerContent flex-column text-center p-3 mb-2 ${!data.contenido && !data.titulo ? 'd-none': ''} `}>
                    <h2 className='fs-custome-title-slider mb-1 ' >{data.titulo}</h2> 
                    <p className={`fs-custome-text-slider`} >{data.contenido}</p> 
                </div> 
                <div className='imgSliderStylesContainer w-100' >
                  <img src={`${IMG_HOST}/${data.nombreImagen}`} className='imgResponsiveSlider' alt={data.nombreImagen} />
                </div> 
            </div> 
            
            </SwiperSlide> } )  
                // imgResponsiveSlider
                // data.nombreImagen
    }
    </Swiper> 
    }
    </>)
}