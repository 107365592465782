/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"; 

export default function Contacto ({dataContacto, activeModal,}){  
    return(<>  
    <section id="contacto" className="pt-5 pb-3 centerContent px-md-5 "> 
        <div className="maxWidth-custome w-100 " > 
            <div className="container-xxl px-md-0 px-4" >  
                <div className="pb-5" > 
                    <h2 className="text-center" >CONTACTANOS</h2> 
                </div> 
                <div > 
                    <address className="row m-0 p-0" > 
                        <div className="col-md-3 col-12" > 
                            <div className="mb-4" > 
                                <p className="h6" ><i className="fa-sharp fa-solid fa-location-dot me-2 "></i>UBICACIÓN</p> 
                                <p className="mb-1" >{dataContacto.direccion}</p> 
                            </div>  
                            <div> 
                                <button onClick={activeModal} className="btn btn-primary mb-md-0 mb-3 " >¡Escríbenos! </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-12"> 
                            <div className="mb-3" > 
                                <p className="h6" ><i className="fa-solid fa-envelope me-2 "></i>EMAIL</p> 
                                <p className="mb-1" >{dataContacto.email}</p>
                            </div> 
                            <div className="" > 
                                <p className="h6" ><i className="fa-solid fa-clock me-2 "></i>HORARIOS</p> 
                                <p className="mb-1" >{dataContacto.horarios}</p> 
                            </div>
                            <div className="" > 
                                <p><i className="fa-solid fa-envelope me-2 "></i> Telefono </p> 
                                <p>{dataContacto.telefono}</p>
                            </div>
                        </div> 
                        <div className="col-md-6 width-frame-responsive-maps"  dangerouslySetInnerHTML={{__html: dataContacto.ubicacion}}  >  
                        </div>
                    </address>
                </div>
            </div> 
        </div>
    </section>
    </>)
}