import React from "react"; 

export default function Modal ({modalActive, children, stylesModal, stylesButtonContainer}) { 
    return (<>  
    <div className={stylesModal}> 
        <div className={stylesButtonContainer} > 
            <button onClick={modalActive} className={`btn btn-sm btn-danger`} > X </button>
        </div> 
        <div> 
            {children}
        </div>
    </div>
    </>)
} 