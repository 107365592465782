import {React, useState, useEffect} from "react"; 

export default function Louder ({stateLouder}){ 
    return( 
        <>  
        <div className={`spinner-border text-primary ${stateLouder ? 'd-block': 'd-none'} `} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        </>
    )
}