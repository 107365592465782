import React from "react";  
import './footer.css' 



export default function Footer({}){ 
    return(
        <> 
            <footer className="px-4" > 
                <div className="container-xxl d-flex justify-content-end  " >  
                    <p>Copyright © PC Oriente {new Date().getFullYear()}</p> 
                </div>
            </footer>
        </>
    )
}