import React from "react";  
import Card from "../card";
import { useEffect } from "react"; 
import useFetch from "../../hooks/usefetch";
import { environment } from "../../enviroments/enviroments";

export default function Section ({children, idSeccion, stylesGridSection, GetIdCliente }) { 
    const fetchCards = useFetch(); 
    useEffect(()=>{ 
        getIdClienteDataCard(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])  

    const getIdClienteDataCard = async () =>{ 
        let idClienteParams = await GetIdCliente(); 

        if(!idClienteParams) return (()=>{
            fetchCards.getAJAX(`${environment.API_URL}/promociones/cards/${idSeccion}`); 
        })();  

        if(idClienteParams){ 
            fetchCards.getAJAX(`${environment.API_URL}/promociones-clientes/cards/${idSeccion}/${idClienteParams}`); 
        }
    }

    const viewData = () =>{ 
        console.log(fetchCards.data); 
    }

    return (<>  
       <section className="py-customeSections d-flex justify-content-center px-md-4 " id={children} onClick={viewData}>  
            <div className="maxWidth-custome w-100" >
                    <div> 
                        <h2 className="text-center mb-5 " >{children}</h2> 
                    </div> 
                    <div className={stylesGridSection} >  
                        { 
                            fetchCards.ispending? (<p>cargando...</p>) : fetchCards.data.map((data,index)=>{ 
                                    return <Card key={index} Cardtitle={data.titulo} nombreImagen={data.nombreImagen} frameVideo={data.frameVideo} imgVideo={data.imgVideo}>{data.texto}</Card>
                            })
                        }
                    </div>  
            </div>
        </section>  
    </>)
}