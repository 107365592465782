import React, {useEffect} from "react";  
import './main.css'; 
import Section from "./seccionesMain";  
import useFetch from "../../hooks/usefetch"; 
import { environment } from "../../enviroments/enviroments";
export default function Main ({dataMainSections, GetIdCliente }) {    

    const fetchSecciones = useFetch();   
    const fetchPresentacion = useFetch(); 
    useEffect(()=>{  
        // fetchSecciones.getAJAX(`${environment.API_URL}/promociones/secciones`) 
        getIdClienteSeccion(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 


    const getIdClienteSeccion = async () =>{ 
        let idClienteParams = await GetIdCliente() 
        if(!idClienteParams) return (()=>{  
            fetchPresentacion.getAJAX(`${environment.API_URL}/promociones/presentacion`); 
        })(); 

        if(idClienteParams){ 
            fetchPresentacion.getAJAX(`${environment.API_URL}/promociones-clientes/presentacion/${idClienteParams}`); 
        }
    }

    const viweData = () =>{ 
        console.log("esta es la informacion de la card"); 
        console.log(fetchSecciones.data) 
    }

    return (  
        <>  
        <div> 
            <div className="" > 

            { 
            fetchPresentacion.ispending ? (<p>Cargando...</p>) : fetchPresentacion.data.map((data)=>{ 
                return <section key={data.idPresentacion} className="container-xxl maxWidth-custome p-4 "  onClick={viweData} >  
                <article className="border rounded-5 p-5 lh-lg  background-mensaje-custome mb-5 " > 
                    <h2 className="text-center  " >{data.Titulo}</h2> 
                    <p className="text-center" >{data.Texto}</p>
                </article>  
            </section>
            })
            }
            <div className="background-primary-custome backgroundSections " >
                {   
                dataMainSections.ispending ? (<p>cargando...</p>) : dataMainSections.data.map((data, index)=><Section key={data.idSeccion} idSeccion={data.idSeccion} stylesGridSection={data.numeroColumnas} GetIdCliente={GetIdCliente}   >{data.nombreSeccion}</Section> ) 
                }
            </div>
            </div>
        </div>
        </>
    )
}