import { useEffect, useRef, useState } from 'react';  
import './App.css';
import useFetch from './hooks/usefetch';  
import Header from './components/header/header';  
import Main from './components/main/main';
import Contacto from './components/contacto/contacto'; 
import Footer from './components/footer/footer'; import { environment } from './enviroments/enviroments'; 
import Slider from './components/swipper'; 
import Modal from './components/modal'; 
import Louder from './components/louder'; 

function App() {    
  const fetchSeccions = useFetch()
  const [activeMenu, setActiveMenu] = useState(false); 
  const [activeFormContact, setActiveFormContact] = useState(false);    
  const [formValue, setFormValue] = useState({
    nombre: "", 
    email: "", 
    telefono: "", 
    comentarios: "",  

  }); 
  const fetchContacto = useFetch();  
  const fetchHeader = useFetch();  
  const [louderState, setLouderState] = useState(false);  
  let refForm = useRef();  
  let buttonSubmitForm = useRef();  
  let errores = { 
    
  } 

  useEffect(()=>{   
    buttonSubmitForm.current.setAttribute("disabled", "true");  
    queriesIdClientes(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); 

  const FormStateValue = (e) =>{  
    setFormValue({ 
      ...formValue, 
      [e.target.name] : e.target.value
    })
  }  

  const onBlurEvent = (e) =>{ 
    if(formValue.nombre === ""){ 
      errores.nombre = "el campo es requerido"
    } 
    if(formValue.email === ""){ 
      errores.email = "el campo es requerido"
    }
    if(formValue.telefono === "" ){ 
      errores.telefono = "el campo es requerido"
    }
    if(formValue.comentarios === ""){ 
      errores.comentarios = "el campo es requerido"; 
    }   
    if(errores.length > 0) { 
      buttonSubmitForm.current.removeAttribute("disabled");   
      console.log("la condicion se esta cumpliendo");
    }  
    console.log(Object.keys(errores).length);  
    if(Object.keys(errores).length === 0){ 
      buttonSubmitForm.current.removeAttribute("disabled"); 
    }
  }

  const formSubmit = (e) =>{  
    e.preventDefault();   
    setLouderState(true);   
    console.log(fetchContacto.data[0].emailModal); 
    fetch(`https://formsubmit.co/ajax/${fetchContacto.data[0].emailModal}`, {
    method: "POST",
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    body: JSON.stringify(formValue)
}).then(response => response.json())
  .then(data => { 
        setLouderState(false);  
        setActiveFormContact(!activeFormContact);  
        refForm.current.reset(); 
        alert("los datos han sido enviados"); 
        })
    .catch(error =>{
      alert("el mensaje sera enviado mas tarde"); 
      setLouderState(false); 
      refForm.current.reset();   
      setActiveFormContact(!activeFormContact);  
  }); 

  }
  const ActiveMenu = () =>{ 
    setActiveMenu(!activeMenu); 
  } 
  const ActiveFormContact = (e) =>{  
    e.preventDefault();
    setActiveFormContact(!activeFormContact); 
  }  
  // const params = new URLSearchParams(Location.search); 
  const GetIdCliente = async () =>{  
    let Location = document.location;  
    let paramters = new URLSearchParams(Location.search); 
    var idClienteParams = paramters.get('idCliente');   
    return idClienteParams; 
  } 

  const queriesIdClientes = async () =>{   
    let idClienteParams = await GetIdCliente(); 
    if(!idClienteParams) return (()=>{ 
      fetchSeccions.getAJAX(`${environment.API_URL}/promociones/secciones`); 
      fetchContacto.getAJAX(`${environment.API_URL}/promociones/contacto`); 
      fetchHeader.getAJAX(`${environment.API_URL}/promociones/header`); 
    })(); 
    if(idClienteParams){ 
      fetchSeccions.getAJAX(`${environment.API_URL}/promociones-clientes/secciones/${idClienteParams}`); 
      fetchContacto.getAJAX(`${environment.API_URL}/promociones-clientes/contacto/${idClienteParams}`); 
      fetchHeader.getAJAX(`${environment.API_URL}/promociones-clientes/header/${idClienteParams}`);
    }
  }

  return (
    <>        
    <div>  
      <div>  
      {
      fetchHeader.ispending? <p>cargando...</p> : fetchHeader.data.map((dataHeader)=> <Header key={dataHeader.idHeader}  dataHeader={dataHeader} activeMenu={ActiveMenu} numberWhats={dataHeader.numeroWhatsApp} /> )  
      }
       <Slider GetIdCliente={GetIdCliente} /> 
        <Modal modalActive={ActiveMenu} stylesButtonContainer={'d-md-none d-flex justify-content-end w-100 pe-2 pt-2 '}  stylesModal={`hamburguesaMenuStyles bg-custome-prmiary  p-sticky-custome2 z-indexCustome mb-5 ${activeMenu ? 'menuActive' : ''} `} > 
                <div className=" p-3 row p-0 m-0 container mx-auto" >  
                    <div className="d-flex justify-content-md-end justify-content-center align-items-md-start gap-5 col-md-12 hamburguesaMenuContainer" >   
                    {  
                    fetchSeccions.ispending ? (<p>Cargando...</p>) : fetchSeccions.data.map((data, index)=>{ 
                      return <div  className="d-flex justify-content-center" key={data.idSeccion} > 
                               <a id={index} onClick={ActiveMenu} className='linkStyleCustome' href={`#${data.nombreSeccion}`} rel="noreferrer"> {data.nombreSeccion || ""} </a>
                             </div>}) 
                    } 
                    <div className='d-flex justify-content-center' > 
                      <a href="#contacto" className='linkStyleCustome' onClick={ActiveMenu} >CONTACTANOS</a>
                    </div> 
                    </div>
                </div> 
          </Modal> 
          <Modal modalActive={ActiveFormContact} stylesModal={`w-100 vh-100 customeformContact  ${activeFormContact? 'formActive' : ''}`} stylesButtonContainer={`d-none`} > 
          <div className='w-100 centerContent mt-5 px-3  ' >   
              <form className='w-custome-container-form ' ref={refForm} >   
                    <div className='d-flex justify-content-end' > 
                      <button onClick={(e)=>{ActiveFormContact(e)}} className='btn btn-danger btn-sm ' > <i className="fa-solid fa-x"></i> </button>
                    </div>
                  <h2 className='text-center mb-3 h3 p-0' > ¡Contactanos! </h2>  
                <div className='form-group' >  
                  <label htmlFor="name" className="form-label fw-bold "> Nombre </label>
                  <input onBlur={(e)=>{onBlurEvent(e)}} value={formValue.name} onChange={(e)=>FormStateValue(e)} name="nombre" type="text" className='form-control' id='nombre' />
                </div> 
                <div className='form-group' > 
                  <label htmlFor="telefono" className='form-label fw-bold' >Telefono</label> 
                  <input onBlur={(e)=>{onBlurEvent(e)}} value={formValue.name} onChange={(e)=>FormStateValue(e)} type="text" name="telefono" id="telefono" className='form-control'/>
                </div> 
                <div> 
                  <label htmlFor="email" className='form-label fw-bold'>Email</label>
                  <input onBlur={(e)=>{onBlurEvent(e)}} value={formValue.name} onChange={(e)=>FormStateValue(e)}  type="email" name='email' className='form-control'/>
                </div> 
                <div className='form-group mb-3 ' > 
                  <label htmlFor="comentarios" className='form-label fw-bold' >Comentarios</label> 
                  <textarea onBlur={(e)=>{onBlurEvent(e)}} value={formValue.name}  onChange={(e)=>FormStateValue(e)} name='comentarios' type="text" className='form-control'  />
                </div> 
                <div className='d-flex justify-content-end' >  
                  <Louder stateLouder={louderState} ></Louder> 
                  <button ref={buttonSubmitForm} onClick={(e)=>formSubmit(e)} className='btn btn-primary ms-3 ' name='' >Enviar</button> 
                </div>
              </form> 
          </div>
          </Modal> 
            <div className='mx-auto ' > 
                <Main dataMainSections={fetchSeccions} GetIdCliente={GetIdCliente} />  
                  {    
                    fetchContacto.data.map((dataContacto)=>fetchContacto.ispending? <p>cargando...</p> : <Contacto  activeModal={ActiveFormContact} key={dataContacto.idContacto} dataContacto={dataContacto} /> )
                  }  
            </div>  
        <Footer />
      </div>
    </div>
    </>
  );
}

export default App;
