export const environment = {
    production: true,
    //SERVIDORES ANTERIORES
    // API_URL: 'https://servidor-pco.herokuapp.com',
    // API_URL: 'https://servidor-pco-new.onrender.com',
  
    //SERVIDORES ACTUALES
    //CONEXION DIGITAL OCEAN
    API_URL: 'https://srvadmin.pcoriente.com',
     //LOCALHOST
    //RUTA DEL SERVIDOR NODE JS
    // API_URL: 'http://localhost:3000',
  
    //RUTA DEL SERVIDOR PHP
    // API_SERVER_PHP: 'http://localhost/archivos-pco',
    //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
    // API_PDF: `http://localhost/archivos-pco/documentos/`,
    // API_IMAGEN: `http://localhost/archivos-pco/imagenes/`,
  
  
    //RUTA DEL SERVIDOR PHP
    // API_SERVER_PHP: '/archivos-pco',
    //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
    // API_PDF: `/archivos-pco/documentos/`,
    // API_IMAGEN: `/archivos-pco/imagenes/`
    API_IMAGEN: `https://vip.pcoriente.com.mx/archivos-pco/imagenes/`,
  };
  